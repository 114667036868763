// extracted by mini-css-extract-plugin
export var contact__confirm = "_confirm-module--contact__confirm--0e256";
export var contact__confirm__back_button = "_confirm-module--contact__confirm__back_button--992c9";
export var contact__confirm__btn = "_confirm-module--contact__confirm__btn--da7d6";
export var contact__confirm__container = "_confirm-module--contact__confirm__container--72823";
export var contact__confirm__input_content = "_confirm-module--contact__confirm__input_content--da713";
export var contact__confirm__input_content__buttons = "_confirm-module--contact__confirm__input_content__buttons--782af";
export var contact__confirm__input_content__sp_star = "_confirm-module--contact__confirm__input_content__sp_star--0a82e";
export var contact__confirm__message = "_confirm-module--contact__confirm__message--a8daf";
export var contact__confirm__submit_button = "_confirm-module--contact__confirm__submit_button--ddb0c";
export var title__wrapper = "_confirm-module--title__wrapper--0a24b";