import React, { useContext, useState } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/seo';
import Title from '../../components/Title';
import * as Styles from '../../styles/pages/contact/_confirm.module.scss';
import { Link } from 'gatsby';
import { ContactFormContext } from '../../context/contactFormContext.js';
import axios from 'axios';
import { navigate } from 'gatsby';
import ContactPageWrapper from '../../components/contact-page-wrapper';
import { css } from '@emotion/react';
import { mediaSp } from '../../styles/media-query';
import BackButton from '../../components/back-button';
import Inner from '../../components/Inner';
import { SectionTitle } from '../../components/SectionTitle';

export default function ContactConfirm() {
  const [submitting, setSubmitting] = useState(false);

  const {
    familyName,
    givenName,
    familyNameKana,
    givenNameKana,
    tel,
    email,
    organization,
    organizationDepartment,
    organizationTitle,
    reason,
    url,
    body,
    isAgree,
  } = useContext(ContactFormContext);

  const onSubmitContactForm = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    if (!isAgree) return;

    const requestBody = {
      'family-name': familyName,
      'given-name': givenName,
      'family-name-kana': familyNameKana,
      'given-name-kana': givenNameKana,
      tel: tel,
      email: email,
      organization: organization,
      'organization-department': organizationDepartment,
      'organization-title': organizationTitle,
      url: url,
      reason: reason,
      body: body,
    };

    axios
      .post(`${process.env.GATSBY_AWS_API_URL}/contact`, requestBody, {
        headers: {
          'X-API-Key': process.env.GATSBY_AWS_API_KEY,
        },
      })
      .then((res) => {
        console.log(res);
        navigate('/contact/thanks/');
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const breadcrumb = [
    { label: 'お問い合わせ', url: '/contact' },
    { label: '入力内容の確認', url: '/contact/confirmation' },
  ];

  return (
    <>
      <Layout breadcrumb={breadcrumb}>
        <Seo
          url="/contact/confirmation"
          title="お問い合わせ・内容確認｜Uniforce（ユニフォース）株式会社"
          description="決算開示・IPO支援業務、Uniforce IPO準備クラウド・決算開示管理クラウドやその他ご相談などはこちらからお問い合わせください。"
          image="https://uniforce.co.jp/og-image.jpg"
          breadcrumbs={breadcrumb}
          meta={[
            {
              name: 'robots',
              content: 'noindex, nofollow',
            },
            {
              name: 'googlebot',
              content: 'noindex, nofollow',
            },
          ]}
        />
        <ContactPageWrapper>
          <div className={Styles.contact__confirm}>
            <Inner>
              <div className={Styles.title__wrapper}>
                <SectionTitle
                  enText="Confirm"
                  titleText="入力内容の確認"
                  css={css`
                    position: relative;
                    padding-top: 140px;
                    z-index: 2;
                    max-width: 1158px;
                    margin: auto;

                    @media (max-width: 1020px) {
                      margin-left: -25px;
                    }
                  `}
                />
              </div>
              <div className={Styles.contact__confirm__container}>
                <p className={Styles.contact__confirm__message}>
                  以下の内容がメールで送信されます。入力した内容に間違いがないかをご確認の上、
                  <br className="pc-only" />
                  「送信する」ボタンをクリックして下さい。
                </p>

                <form
                  onSubmit={onSubmitContactForm}
                  className={Styles.contact__confirm__input_content}
                >
                  <table>
                    <tr>
                      <th>
                        <span
                          className={
                            Styles.contact__confirm__input_content__sp_star
                          }
                        >
                          ★
                        </span>
                        お名前
                      </th>
                      <td>
                        {familyName} {givenName}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span
                          className={
                            Styles.contact__confirm__input_content__sp_star
                          }
                        >
                          ★
                        </span>
                        ふりがな
                      </th>
                      <td>
                        {familyNameKana} {givenNameKana}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span
                          className={
                            Styles.contact__confirm__input_content__sp_star
                          }
                        >
                          ★
                        </span>
                        電話番号
                      </th>
                      <td>{tel}</td>
                    </tr>
                    <tr>
                      <th>
                        <span
                          className={
                            Styles.contact__confirm__input_content__sp_star
                          }
                        >
                          ★
                        </span>
                        メールアドレス
                      </th>
                      <td>{email}</td>
                    </tr>
                    <tr>
                      <th>部署（任意）</th>
                      <td>{organizationDepartment}</td>
                    </tr>
                    <tr>
                      <th>役職（任意）</th>
                      <td>{organizationTitle}</td>
                    </tr>
                    <tr>
                      <th>
                        <span
                          className={
                            Styles.contact__confirm__input_content__sp_star
                          }
                        >
                          ★
                        </span>
                        会社URL
                      </th>
                      <td>{url}</td>
                    </tr>
                    <tr>
                      <th>
                        <span
                          className={
                            Styles.contact__confirm__input_content__sp_star
                          }
                        >
                          ★
                        </span>
                        お問い合わせ内容
                      </th>
                      <td>{reason}</td>
                    </tr>
                    <tr>
                      <th>内容詳細（任意）</th>
                      <td>{body}</td>
                    </tr>
                  </table>

                  <div
                    className={Styles.contact__confirm__input_content__buttons}
                  >
                    <Link to="/">
                      <div
                        css={css`
                          background: #fff;
                          border: 1px solid #2e2f34;
                          border-radius: 34px;
                          text-align: center;
                          width: 320px;
                          padding-top: 17px;
                          padding-bottom: 17px;
                          margin-right: 30px;

                          position: relative;
                          overflow: hidden;
                          text-decoration: none;
                          display: inline-block;
                          border: 1px solid #555;
                          text-align: center;
                          outline: none;
                          transition: ease 0.2s;

                          :before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            background: #252a40;
                            width: 100%;
                            height: 100%;
                            transition: transform 0.6s
                              cubic-bezier(0.8, 0, 0.2, 1) 0s;
                            transform: scale(0, 1);
                            transform-origin: right top;
                          }

                          span {
                            position: relative;
                            z-index: 3;
                            color: #333;
                          }

                          :hover {
                            span {
                              color: #fff;
                            }

                            :before {
                              transform-origin: left top;
                              transform: scale(1, 1);
                            }
                          }

                          ${mediaSp} {
                            display: none;
                          }
                        `}
                      >
                        <span
                          css={css`
                            color: #252a40;
                            margin: 0;
                          `}
                        >
                          戻る
                        </span>
                      </div>
                    </Link>

                    <button
                      type="submit"
                      disabled={submitting}
                      css={css`
                        background: #252a40;
                        border: 1px solid #2e2f34;
                        border-radius: 34px;
                        text-align: center;
                        width: 320px;
                        height: 68px;
                        padding: 0;
                        color: #fff;

                        position: relative;
                        overflow: hidden;
                        text-decoration: none;
                        display: inline-block;
                        border: 1px solid #555;
                        text-align: center;
                        outline: none;
                        transition: ease 0.2s;

                        :before {
                          content: '';
                          position: absolute;
                          top: 0;
                          left: 0;
                          z-index: 2;
                          background: #fff;
                          width: 100%;
                          height: 100%;
                          transition: transform 0.6s
                            cubic-bezier(0.8, 0, 0.2, 1) 0s;
                          transform: scale(0, 1);
                          transform-origin: right top;
                        }

                        ${mediaSp} {
                          max-width: 300px;
                          width: 100%;
                          padding-top: 7px;
                          padding-bottom: 7px;
                          font-size: 17px;
                          font-weight: bold;
                          margin: auto;
                        }

                        span {
                          position: relative;
                          z-index: 3;
                          color: #fff;
                        }

                        :hover {
                          span {
                            color: #252a40;
                            font-weight: bold;
                          }

                          :before {
                            transform-origin: left top;
                            transform: scale(1, 1);
                          }
                        }
                      `}
                    >
                      <span>送信する</span>
                    </button>
                  </div>
                </form>
              </div>

              <div className={Styles.contact__confirm__btn}>
                <Link to="/contact">
                  <BackButton to="/contact" />
                </Link>
              </div>
            </Inner>
          </div>
        </ContactPageWrapper>
      </Layout>
    </>
  );
}
